import {
  FreeCamera,
  Vector3,
  HemisphericLight,
  MeshBuilder,
} from '@babylonjs/core';
import { Link } from 'gatsby';
import React, { useState, useCallback } from 'react';
import '../assets/stylesheets/pages/babylon.scss';
import '../assets/stylesheets/pages/babylonjs-hand-inserted.scss';
import BabylonScene from '../components/BabylonScene/BabylonScene';

let box;

export default function BabylonjsHandInserted() {
  const [rotationSpeed, setRotationSpeed] = useState(10);

  const onSceneReady = useCallback((scene) => {
    // This creates and positions a free camera (non-mesh)
    const camera = new FreeCamera('camera1', new Vector3(0, 5, -10), scene);
    // This targets the camera to scene origin
    camera.setTarget(Vector3.Zero());
    const canvas = scene.getEngine().getRenderingCanvas();
    // This attaches the camera to the canvas
    camera.attachControl(canvas, true);
    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.7;
    // Our built-in 'box' shape.
    box = MeshBuilder.CreateBox('box', { size: 2 }, scene);
    // Move the box upward 1/2 its height
    box.position.y = 1;
    // Our built-in 'ground' shape.
    MeshBuilder.CreateGround('ground', { width: 6, height: 6 }, scene);
  });
  /**
   * Will run on every frame render.  We are spinning the box on y-axis.
   */
  const onRender = useCallback(
    (scene) => {
      if (box !== undefined) {
        const deltaTimeInMillis = scene.getEngine().getDeltaTime();
        const rpm = rotationSpeed;
        box.rotation.y += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
      }
    },
    [rotationSpeed]
  );

  return (
    <div>
      <BabylonScene
        antialias
        onSceneReady={onSceneReady}
        onRender={onRender}
        id="my-canvas"
      />

      <div className="button-container">
        <p>Rotation Speed</p>
        <p className="speed-control">
          <button
            type="button"
            className="button button--slower"
            onClick={() => setRotationSpeed((prevSpeed) => prevSpeed - 1)}
          >
            -
          </button>
          <span className="rotation-speed">{rotationSpeed}</span>
          <button
            type="button"
            className="button button--faster"
            onClick={() => setRotationSpeed((prevSpeed) => prevSpeed + 1)}
          >
            +
          </button>
        </p>
        <p>
          <Link to="/babylonjs-react-gatsby">
            Read a full description of how to add and control a babylonjs scene
            from a React page.
          </Link>
        </p>
      </div>
    </div>
  );
}
